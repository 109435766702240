// panels

.card { border: none; }

.card-block {
   padding-top: 0;
}
h4.card-title {
   font-size: rem(18);
}


nav.panel_nav { 
   margin-top: $large-pad;
   margin-bottom: $large-pad;
   figure {
      position: relative;
      margin: $medium-pad;
      overflow: hidden;

      img.figure-img {
         margin-bottom: 0;

         @include media-breakpoint-down(lg) {
            max-width: 400px !important;
         }
         @include media-breakpoint-down(md) {
            width: 100%;
            max-width: 100% !important;
         }

      }

      &:nth-of-type(2n) {
         &.panel {
         }
      }

      &>img {
//       width: 50%;
//       max-width: 50%;
      }

   }

}
