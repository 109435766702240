// footer

footer {

   .bottom_bar {
      background-color: $bottom-panel-bg;
      @extend .shadow_mini;

      .company {
         padding-top: $medium-pad;
         padding-bottom: $medium-pad;
         @extend .shadow_text;
      }
   }


   margin-top: rem(50);
   .jumbotron-disabled {
      color: $light-grey;
//      background-color: $robert_main;
//      @extend .shadow_mini;
//      @extend .shadow_text;
      a,
      a:link {
         color: $light-grey;
      }
      .footer_nav {
         margin-top: $medium-pad;
      }
      .social_nav {
         margin-top: $medium-pad;
      }
   }
   .col_help_nav {
//      @extend .right-horizontal;
   }

   .info {
      .row {
         margin-right: 0;
         margin-left: 0;
      }
    }

   .copyright {
      margin-top: $small-pad;
      font-size: rem(12);
      text-align: center;
      color: $grey;
      a,
      a:link {
         color: $grey;
      }
   }
}
