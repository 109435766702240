// no of languages to show
//$lang_count: 5;
//$lang_height: 41px;

// $lp_height: calc($lang_height * $lang_count) + 1px;
//$lp_height: 206px;

#lang_pick,
#area_pick,
#search_pick {
    .btn {
       font-size: rem(18) !important;
    }
    margin-right: $mini-pad;
   .dropdown-menu {
      min-width: 0.5em;
   }
}


#search_pick {
   input.search_input {
       width: auto;
       width: 250px !important;
   }

   .dropdown {
   }
}


.login_pick {
   .login_details {
      font-size: 75%;
      .name {
         font-weight: bold;
      }
      .role {
         font-style: italic;
      }
   }
}


.popover {

   .popover-title {
   }

   &.popover-bottom::after,
   &.bs-tether-element-attached-top::before,
   &.bs-tether-element-attached-top::after {
      left: 95% !important;
   }
   .popover-content {
      width: 425px;

      @include media-breakpoint-down(xs) {
          width: 310px;
      }
   }

   @include media-breakpoint-down(xs) {
          left: 85px !important;
   }
}

