// make svg clickable
a.svg {
  position: relative;
  display: inline-block; 
}
a.svg:after {
  content: ""; 
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left:0;
}



a.fancybox img {
//   padding: 1px 1px 21px 1px;
   padding: 0;
   background: transparent url('/misc_/zms/zoom.gif') bottom right no-repeat;
//   background-color: transparent;
}
