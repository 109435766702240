// niels jquery-ui nplayer

.jp-gui {
	position:relative;
	padding:20px;
	width:670px;
}
.jp-gui.jp-no-volume {
	width:480px;
}
.jp-gui ul {
	margin:0;
	padding:0;
}
.jp-gui ul li {
	position:relative;
	float:left;
	list-style:none;
	margin:2px;
	padding:4px 0;
	cursor:pointer;
}
.jp-gui ul li a {
	margin:0 4px;
}
.jp-gui li.jp-repeat,
.jp-gui li.jp-repeat-off {
	margin-left:344px;
}
.jp-gui li.jp-mute,
.jp-gui li.jp-unmute {
	margin-left:20px;
}
.jp-gui li.jp-volume-max {
	margin-left:120px;
}
li.jp-pause,
li.jp-repeat-off,
li.jp-unmute,
.jp-no-solution {
	display:none;
}
.jp-progress-slider {
	position:absolute;
	top:28px;
	left:100px;
	width:280px;
}
.jp-progress-slider .ui-slider-handle {
	cursor:pointer;
}
.jp-volume-slider {
	position:absolute;
	top:31px;
	left:508px;
	width:80px;
	height:.4em;
}
.jp-volume-slider .ui-slider-handle {
	height:.75em;
	width:.75em;
	cursor:pointer;
}
.jp-gui.jp-no-volume .jp-volume-slider {
	display:none !important;
}
.jp-current-time,
.jp-duration {
	position:absolute;
	top:42px;
	font-size:0.75em;
	cursor:default;
}
.jp-current-time {
	left:100px;
}
.jp-duration {
	right:266px;
}
.jp-gui.jp-no-volume .jp-duration {
	right:70px;
}
.jp-clearboth {
	clear:both;
}


@include media-breakpoint-down(xs) {
   .jp-progress-slider { width: 160px; }
   .jp-gui li.jp-repeat,
   .jp-gui li.jp-repeat-off { display: none !important; }
   .jp-gui.jp-no-volume { width: 290px; }
   .jp-gui.jp-no-volume .jp-duration {
      right: 20px;
   }
}
