// site

* {
   box-sizing: border-box;
}


::selection {
   background-color: transparent;
}

::-moz-selection {
   background-color: transparent;
}

div#carousel1 {
//    overflow: hidden;
//    height: 300px;
    @include media-breakpoint-down(lg) {
//       height: 240px;
    }
    @include media-breakpoint-down(lg) {
//       height: 220px;
    }
    @include media-breakpoint-down(sm) {
//       height: 200px;
    }
    @include media-breakpoint-down(xs) {
//       height: 120px;
    }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
//  display: block;
}

main {
  margin-top: $medium-pad;
  h1.title {
     overflow-wrap: break-word;
  }
  img {
     max-width: 100%;
     height: auto;
  }

}

