// header


header {

   img.logo_img {
      width: 100%;
      margin-top: $medium-pad;
      margin-bottom: $medium-pad;
   }

   .head-btns-right {
      width: auto;
        padding: $medium-pad;
        padding-right: 0;
//        font-size: rem(20);

        i.mdi {
//           font-size: 1.5em;
        }

        @include media-breakpoint-down(lg) {
            font-size: rem(18);
        }
        @include media-breakpoint-down(md) {
           margin: 0 auto !important;
           float: none !important;
        }
   }


   .top_bar {
      min-height: rem(96);
      background-color: $top-panel-bg;
   }


   object.logo {
      width: 100%;
      pointer-events: none;
      margin-top: $medium-pad;
      margin-bottom: $large-pad;
      @include media-breakpoint-down(sm) {
         width: 100%;
      }
   }

   div.streams-app {
      // background-color: $light-grey;
      color: $grey;
      .title {
         font-size: rem(11);
      }
      button {
         font-size: rem(11) !important;
         padding: rem(4) !important;
      }
   }

   div.nowplays-app {
      color: $grey;
//      border-top-right-radius: 0 !important;

      .sendung { font-weight: bold; }

      &.musikbox {
         color: $light-grey;
      }
   }

}
