.teaser_row {
   padding: $medium-pad;
   min-height: rem(100);
}


div.ZMSTeaserContainer > div {
//   display: inline-block;
//   float: left;
//   width: 200px !important;
   min-height: 150px;
//   @include media-breakpoint-down(sm) {
//      justify-content: center !important;
//      align-items: center !important;
//   }
}

div.ZMSTeaserElement {
//   width: 200px;
}




