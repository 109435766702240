// grid stuff */

.content {
   margin-top: $medium-pad;

   .alert {
      margin-bottom: $large-pad;
   }
}


.teaser_row {
   margin-top: rem(48);
}

