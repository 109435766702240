$ease-break: cubic-bezier(0.07, 0.54, 0, 0.99);

$hard-site-url-prefix:	"http://lienert3.zope.syndicat.com";

//path vars
$assetPath : "/theme_robert_main/style/images";
$font-path: "/theme_robert_main/style/fonts";
$fa-font-path: $font-path;



// Robert Theme

$robert_main:		#006caa;
$robert_main_dark:	#003a5e;
$robert_main_medium:	#00a2ff;
$robert_main_light:	lighten($robert_main, 50%);
$body-bg:		#fbfefe;
$jumbotron-bg:		$robert_main;
$top-panel-bg:          $robert_main;
$bottom-panel-bg:       $robert_main;

$rdt_color:		#437A32;

$bgf_color:		#2b517a;

$primary:               $robert_main;
$secondary:             $rdt_color;


$white:           #FBFBFB;
$light-grey:      #E6E6E6;
$medium-grey:     #888888;
$grey:            #3c3c3b;
//$black:           rgb(24,24,26);
$black:           darken($robert_main, 98%);

$bg_grey:         #ebeced;

$orange:          #FF9900;
$green:           #0e7633;
$red:             #e03b0f;
$red_light:       lighten($red, 35%);

$blue_full:       #077fff;
$blue:            #006caa;



$odd:                   $light-grey;
$even:                  transparent;


// pads / sizes
$mini-pad: 0.25rem;
$small-pad: 0.5rem;
$medium-pad: 1rem;
$large-pad: 2rem;

// font
$mini-font: 0.5rem;
$small-font: 0.75rem;
$medium-font: 1rem;
$large-font: 1.5rem;


$small-factor: 1;
$medium-factor: 2;
$large-factor : 3;

$mq-responsive: true;

$mq-breakpoints: (
        mobile:  320px,
        tablet:  740px,
        desktop: 980px,
        wide:    1300px,

  // Tweakpoints
        desktopAd: 810px,
        mobileLandscape: 480px
);

$mq-static-breakpoint: desktop;

// bootstrap Anpassungen
$dropdown-link-active-color:	$robert_main;
$dropdown-link-active-bg:	lighten($robert_main, 60%);

