/* new form styles */
::-moz-focus-inner {
    border: 0;
    padding: 0;
}

input:not([type="submit"],[type="reset"],[type="file"],[class="no-form-control"]),
select:not([class="no-form-control"]),
textarea:not([class="no-form-control"]) {
    @extend .form-control;
}

.no-full-width {
   width: auto !important;
}


//select.date {
//   @extend .form-control;
//}

form .nowrap,
input[type="radio"],
select.date {
   display: inline;
   width: auto;
}

//select.date {
//   margin-right: 0.5em;
//   margin-left: 0.5em;
//}

input {
   &[type="radio"],
   &[type="checkbox"] {
      display: inline;
      margin-top: $small-pad;
      @include media-breakpoint-up(lg) {
         width: auto;
      }
   }
   &[type="radio"] {
      @include media-breakpoint-down(lg) {
         margin-left: $small-pad;
      }
   }
}

.ZMSForm {
   width: 100%;
   fieldset {
      background-color: $gray-200;
      border-color: $gray-400;
      padding-bottom: $medium-pad;
      @include rounded(rem(5));
      margin-bottom: $large-pad; 

      legend {
         width: 33%;
         color: $white;
         font-size: rem(18);
         padding: $mini-pad;
         padding-left: $medium-pad;
         margin-left: $medium-pad;
         background-color: $robert_main_medium;
         @include rounded();

         @include media-breakpoint-down(md) {
           width: 45%;
         }
         @include media-breakpoint-down(sm) {
           width: 65%;
         }
         @include media-breakpoint-down(xs) {
           width: 85%;
         }
      }


      div.field {
         width: 100%;

         div.required {
            label { color: $red; }
         }
         div.optional { }

         div.labelbox {
            width: 30%;
            padding: $mini-pad;
            padding-left: $medium-pad;
            display: block;
            float: left;


            @include media-breakpoint-down(md) {
               float: none;
               clear: both;
               width: 100%;
            }

            label {
               font-weight: bold;

               div.description {
                  font-size: 75%;
               }

               div.description {
                  font-size: 75%;
               }
            }
            @include media-breakpoint-down(sm) {
               margin-top: $medium-pad;
            }
         }
         div.inputbox {
            width: 60%;
            float: left;
            display: block;

            > div {
               margin-left: $small-pad;
            }


// niels test
@include media-breakpoint-down(md) {
   float: none;
   clear: both;
   width: 100%;
}

         }

         input,
         textarea,
         select {
//            border: 1px solid $grey;
//            background-color: $white !important;
         }
         input:focus,
         textarea:focus {
//            background-color: $robert_main_light;
         }

         input {
            .zip {
               width: 7em;
            }
         }

      }
      .odd { background-color: $odd; }
      .even {  }
      .clearfix {}
   }
   p.description {
      font-weight: bold;
   }
}


input.redborder { border: 1px #cc0000 dotted !important; background-color: #d1b1b1 !important; }

.searchform {
    margin-bottom: $medium-pad;
}


// page button panel
.page_btns {
   width: 100%;
   padding-top: $small-pad;
   padding-bottom: $small-pad;
   margin-bottom: $small-pad;
   button,
   a {
     margin-bottom: $medium-pad;
     @include media-breakpoint-down(md) {
        width: 100%;
     }
   }
}
