// ZMS custom modifyers



// srg_cal_timestamp
span.srg_timestamp {
   font-size: 0.75rem;
}


// card w image

.card.infolink {
   @include media-breakpoint-down(sm) {
      margin-bottom: $large-pad;
      .topimg {
         img {
            width: 100% !important;
         }
      }
      h4 {
         margin-top: $medium-pad;
      }
   }
}


.pf-carousel  {
    .pf-item-description {
       a {
          color: $light-grey !important;
       }
    }
}


ul.ausstattung {
   width: 100%;

   @include media-breakpoint-up(sm) {
      column-count: 2;
      -moz-column-count: 2;
      -webkit-column-count: 2;
   }

   margin-bottom: $medium-pad;
   padding: $small-pad;
   background-color: $robert_main_light;


   li {
      &:before {
         color: $green;
         font-weight: bold;
         padding-right: $mini-pad;
      }
   }
}


// formats
.alert {
   @each $color, $value in $theme-colors {
      &.alert-#{$color} {
         a {
            color: darken($value, 10%);
            font-weight: $alert-link-font-weight;
         }
      }
   }
}

// products
div.prod_timespan {
   margin-bottom: $large-pad;

   .date,
   .custom {
      font-weight: bold;
   }
}

div.prod_legend {
   margin-top: $large-pad;
}

// angebote / products


.prod_free_content {
   margin-top: $large-pad;
   table {
      width: 100% !important;

      td,
      th {
         text-align: right !important;
         width: 30% !important;
         &::nth-of-type(1) {
            width: auto;
            text-align: left !important;
         }
      }

      // break on smallest screens
      @include media-breakpoint-down(xs) {
         tr {
            display: table;  width:100%;
         }
         td {
            display: table-row;
            text-align: center !important;
//          padding-right: $small-pad !important;

            img {
               max-width: 100%;;
               margin: 0 auto;
            }
         }
         th {
            display: table-row;
         }
         tr {
            margin-bottom: $large-pad;
         }
      }
   }
}


.prod_teaser_action {
   margin-top: $medium-pad;
   margin-left: $large-pad;
   width: 30%;
   @include media-breakpoint-down(xs) {
      width: 80%;
   }
   z-index: 600;
   margin-right: $medium-pad;
   padding: $small-pad;
   background-color: $robert_main_light;
   @include rounded();
   @include rotate(10deg);
   @extend .shadow_light;
   h5 {
      cont-size: rem(20);
      color: $red;
      font-weight: bold;
      font-style: none;
   }
   .text_sub {

   }
}
