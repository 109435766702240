.news {
  .news_header {
    .permalink {
      margin-top: $medium-pad;
      font-size: rem(12);
    }
  }

  div.buttons {
    margin-top: $large-pad;
  }

  div.news_images {
    margin-top: $large-pad;
  }
}

.podcast {
   .podcast_header {
   }
   .head_player {
      margin-bottom: $large-pad;;
   }
   .playerbox {
      margin-top: $small-pad;
      display: inline-block;     
   }

   .mp3ads {
      margin-top: $small-pad;
      font-style: italic;
   }

   .disclaimer {
      margin-top: $medium-pad;
      font-style: italic;
   }
}
