// backport 4.x

.dropleft {
  .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: $small-pad;
  }

  .dropdown-toggle {
    &::before {
      vertical-align: 0;
    }
  }
}








.center-vertical {
  display: flex;
  align-items: center;
}
.center-horizontal {
  display: flex;
  justify-content: center;
}

.right-horizontal {
//  display: flex;
  text-align: right;
  float: right !important;
  justify-content: right;
}

.rounded{
  border-radius: 5px;
}

.no-padding{
  padding: 0 !important;
}

.border-bottom{
  position: relative;
  display: inline-block;
  &:after{
    bottom: -5px;
    display: block;
    content:'';
    position:absolute;
    width:100%;
    height:1px;
    background: -webkit-gradient(linear, 0 0, 100% 0,color-stop(0, grey), color-stop(1, transparent));
  }
}



div.empty { background-color: transparent !important; }

.clicker:hover {
	background-color: #f4f4f4;
}

/** nice stuff **/
.shadow_mini {
   @include box-shadow(0, 1px, 4px, rgba(41,51,57,.5))
}

.shadow_mini {
   @include box-shadow(0, 1px, 4px, rgba(41,51,57,.5))
}

.shadow_light {
   @include box-shadow(0, 4px, 4px, rgba(41,51,57,.2))
}


.shadow_text {
   text-shadow: 0 1px 1px rgba(0,0,0,.5);
}


.greybox {
   padding: 1em;
   background-color: #eeeeee; 
   border-color: #444444; 
}


.disable-user-select{
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.enable-user-select{
    -webkit-user-select: text !important;
    -moz-user-select: text !important;
    -ms-user-select: text !important;
    user-select: text !important;
}
